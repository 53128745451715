import { AppCenteredSpinner } from '@bemer/web-app-base';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

const PageNotFoundPage = (): JSX.Element => {
  useEffect(() => {
    navigate('/');
  }, []);

  return (
    <AppCenteredSpinner>
      <p>Page Not Found</p>
    </AppCenteredSpinner>
  );
};

export { PageNotFoundPage as default };
